*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Work Sans", sans-serif;
  /* font-family: "Poppins",sans-serif; */
}

input:active{
  outline: none !important;
}

input:focus{
  outline: none !important;
}

.leaflet-container {
  height: 50vh;
}

.single_active_ride_map{
  padding: 0px !important;
  width: 80vw !important;
  border-radius: 10px;
}


.homepages{
  display: flex;
  flex-direction: row;
  background-color: #000029;
}

.table-row-light {
  background-color: #000029;
  /* color: white; */
  font-size: 1rem;
  color: rgb(191, 191, 191) !important;
  border-top: none !important;
  border-bottom:none !important;
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
}

.value-table-row-light{
  background-color: #000029;
  /* color: white; */
  font-size: 1rem;
  border-bottom: 1px solid #2F3339;
  color: rgb(191, 191, 191) !important;
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
}

.value-table-row-light-driver{
  background-color: #000029;
  /* color: white; */
  font-size: 1rem;
  /* border-bottom: 1px solid #2F3339; */
  color: rgb(191, 191, 191) !important;
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
}

.values{
  color: rgb(191, 191, 191) !important;
}

.active{
  border-left-color: #0475FF;
}



.table-row-dark {
  background-color: #000029;
  /* color: white;  */
  color: rgb(191, 191, 191) !important;
  border-top: none !important;
  border-bottom: none !important;
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
}

.ant-table-cell-row-hover{
  background-color: black !important;
}

.ant-table-cell{
  background-color: #000029 !important;
}

.ant-table-thead .ant-table-cell {
  background-color: #000029 !important;
  border-bottom: 1px solid #2F3339 !important;
  color: #ffffff !important;
  /* border: none !important; */
}

.css-18pjc51-MuiFormLabel-root-MuiInputLabel-root{
  color: #6E72A2 !important;
  text-transform: capitalize;
}

.css-j882ge-MuiInputBase-root-MuiOutlinedInput-root{
  color: white !important;
  outline: white !important;
}

.MuiInputBase-root{
  color: white !important;
}

.MuiFormLabel-root,.MuiInputLabel-root{
  color: white !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #6E72A2 !important;
}

.MuiOutlinedInput-notchedOutline{
  border-color: #6E72A2 !important;
}

.ant-modal-content{
  background-color: #000029 !important;
  border: 2px solid rgb(193, 193, 193) !important;
}

.ant-modal-title{
  background-color: #000029 !important;
  color: white !important;
}